import React from 'react';
import NelliOne from '../images/Girls/Benelli/NelliOne.jpeg';
import NelliTwo from '../images/Girls/Benelli/NelliTwo.jpg';
import NelliThree from '../images/Girls/Benelli/NelliThree.jpg';
import NelliFour from '../images/Girls/Benelli/NelliFour.jpeg';
import NelliFive from '../images/Girls/Benelli/NelliFive.jpeg';
import Nelli from '../images/Girls/Benelli/Nelli.jpeg';
import NelliShow from '../images/Girls/Benelli/NelliShow.jpeg';

import { column, row, imageSeparator, halfImage, fullImage } from '../../app/styles';

class NelliMore extends React.Component {

    render() {
        return (
            <div style={column}>
                <div style={{ width: '100%', marginTop: '10px' }}></div>
                <div style={row}>
                    <img style={halfImage} src={Nelli} alt="Nelli Six" />
                    {/* <div style={imageSeparator}></div> */}
                    {/* <img style={halfImage} src={NelliShow} alt="Nelli show" /> */}
                </div>
                <div style={row}>
                    <img style={halfImage} src={NelliOne} alt="Nelli One" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={NelliTwo} alt="Nelli Two" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={NelliFour} alt="Nelli Four" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={NelliFive} alt="Nelli Five" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={NelliThree} alt="The girls" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={NelliShow} alt="Nelli show" />
                </div>
            </div>
        );
    }
}

export default NelliMore;