import React from 'react';
import girlgirls from '../images/Home/girlsgirls.jpeg';
import labmom from '../images/Home/labmom.jpeg';
import remi from '../images/Home/RemiShow.jpg';
import kidsanddogs from '../images/Home/kidsanddogs.jpg';

import { column, row, fullImage, twoImage, imageSeparator } from '../../app/styles';

class Home extends React.Component {

    render() {
        return (
            <div style={column}>
                <img style={fullImage} src={kidsanddogs} alt="Home" />
                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                </div>
                <div style={column}>
                    <div style={{ width: '77%', color: 'white' }}>
                        <p>
                            From the time that Dan and I were able to purchase our first home in 1992, we have had a Labrador Retriever.
                            After losing our first one and experiencing that horrible feeling of coming home to an empty house, we were determined to never be a 
                            single dog household again. Thus began our Labrador adventures. We had had several non-breeding dogs since then but with the acquisition 
                            of a beautiful girl with excellent bloodlines, we were then able to start a new adventure… a bucket list 
                            item for us. We are now an up and coming REPUTABLE BREEDER OF QUALITY, HEALTH TESTED, IN-HOME RAISED LABRADOR RETRIEVER PUPPIES!
                        </p>
                    </div>
                </div>
                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                </div>
                <div style={column}>
                    <img style={twoImage} src={remi} alt="Dog Show" />
                    <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                </div>
                    <div style={{ width: '77%', color: 'white' }}>
                        <p>
                            Regarding the “Adopt, Don’t Shop” argument, we’ve adopted three labs and purchased three. YOU DON’T KNOW WHAT YOU ARE GETTING! You don’t know if they were neglected or abused. 
                            You may not know of past health issues that may come back or linger on, you don’t know if you will ever be able to trust them alone in your home on your carpets or with 
                            your electrical cords…  Seriously, there is some risk with the reward.
                        </p>
                    </div>
                </div>
                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                </div>
                <div style={row}>
                    <img style={twoImage} src={girlgirls} alt="Home1" />
                    <div style={imageSeparator}></div>
                    <img style={twoImage} src={labmom} alt="Home2" />
                </div>
                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                </div>
                <div style={row}>
                <div style={column}>
                    <h3 style={{ marginTop: '20px', textAlign: "center" }}>
                        We are members of the Puget Sound Labrador Retriever Association and the Spokane Kennel Club.
                    </h3>
                    <h4 style={{ margin: '0px', textAlign:"center" }}>
                        See their website here: { }
                        <a href="https://www.pslra.org/" target="_blank" rel="noopener noreferrer" style={{color:"grey"}}>https://www.pslra.org/</a>
                    </h4>
                </div>
                </div>
            </div>
        );
    }
}

export default Home;