const column = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
};

const row = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: '77%'
}

const leftRow = {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    flexDirection: "row",
    width: '77%'
}

const fullImage = {
    border: '5px solid black',
    width: '77%',
    marginBottom: '5px'
}

const mostImage = {
    border: '5px solid black',
    width: '50%',
    marginBottom: '5px'
}

const twoImage = {
    width: '38%',
    border: '5px solid black',
    height: 'auto !important'
}

const imageSeparator = {
    width: '20px'
}

const associationImage = {
    background: 'white',
    border: '5px solid black',
    marginTop: '2px',
    marginBottom: '2px',
    marginRight: '15px',
    width: '200px',
    height: '200px'
}

const halfImage = {
    border: '5px solid black',
    width: '35%',
    marginBottom: '20px',
    height: 'auto !important'
}

const contactWord = {
    color: "white",
    textAlign: "center",
    width:"100%",
}

const seeMoreButton = {
    color: "white",
    background: "black",
    fontWeight: "bold",
    border: "2px solid white",
    borderRadius: "5px",
    padding: "2px"
}

export { column, row, leftRow, associationImage, fullImage, twoImage, imageSeparator, halfImage, contactWord, seeMoreButton, mostImage };