import React from 'react';
import Jumping from '../images/Puppies/PlayerLitter/Jumping.jpg';
import NoLeg from '../images/Puppies/PlayerLitter/noLeg.jpg';
import Puppies from '../images/Puppies/PlayerLitter/Puppies.jpg';
import PuppiesTwo from '../images/Puppies/PlayerLitter/PuppiesTwo.jpg';
import PuppySide from '../images/Puppies/PlayerLitter/PuppySide.jpg';
import PuppyWalk from '../images/Puppies/PlayerLitter/PuppyWalk.jpg';
import Siggie from '../images/Puppies/PlayerLitter/Siggie.jpeg';
import WagonBoy from '../images/Puppies/PlayerLitter/WagonBoy.jpg';

import { column, row, imageSeparator, halfImage } from '../../app/styles';

class PlayerLitterMore extends React.Component {

    render() {
        return (
            <div style={column}>
                <div style={{ width: '100%', marginTop: '10px' }}></div>
                <div style={row}>
                    <img style={halfImage} src={Jumping} alt="Puppy One" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={PuppyWalk} alt="Puppy Two" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={NoLeg} alt="Puppy Three" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={WagonBoy} alt="Puppy Four" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={PuppySide} alt="Puppy Five" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Siggie} alt="Puppy Six" />
                </div><div style={row}>
                    <img style={halfImage} src={Puppies} alt="Puppy Seven" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={PuppiesTwo} alt="Puppy Eight" />
                </div>
            </div>
        );
    }
}

export default PlayerLitterMore;