import React from 'react';
import ShastaPuppies from '../images/Puppies/KosmoLitter/ShastaBabies.jpg';
import Pups1 from '../images/Puppies/KosmoLitter/Pups1.jpeg';
import puppies1 from '../images/Puppies/KosmoLitter/puppies1.jpg';
import puppies2 from '../images/Puppies/KosmoLitter/puppies2.jpg';
import puppies3 from '../images/Puppies/KosmoLitter/puppies3.jpg';
import puppies4 from '../images/Puppies/KosmoLitter/puppies4.jpg';
import puppies5 from '../images/Puppies/KosmoLitter/puppies5.jpg';
import puppies6 from '../images/Puppies/KosmoLitter/puppies6.jpg';
import puppies7 from '../images/Puppies/KosmoLitter/puppies7.jpg';
import puppies8 from '../images/Puppies/KosmoLitter/puppies8.jpg';
import puppies9 from '../images/Puppies/KosmoLitter/puppies9.jpg';
import puppies10 from '../images/Puppies/KosmoLitter/puppies10.jpg';
import puppies11 from '../images/Puppies/KosmoLitter/puppies11.jpg';
import puppies12 from '../images/Puppies/KosmoLitter/puppies12.jpg';


import { column, row, imageSeparator, halfImage } from '../../app/styles';

class KosmoLitterMore extends React.Component {

    render() {
        return (
            <div style={column}>
                <div style={{ width: '100%', marginTop: '10px' }}></div>
                <div style={row}>
                    <img style={halfImage} src={ShastaPuppies} alt="Puppies" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Pups1} alt="Puppies One" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={puppies1} alt="Puppies2" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={puppies2} alt="Puppies Two" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={puppies3} alt="Puppies3" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={puppies4} alt="Puppies Three" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={puppies5} alt="Puppies4" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={puppies6} alt="Puppies Four" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={puppies7} alt="Puppies5" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={puppies8} alt="Puppies Five" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={puppies9} alt="Puppies6" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={puppies11} alt="Puppies Six" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={puppies10} alt="Puppies7" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={puppies12} alt="Puppies Seven" />
                </div>
            </div>
        );
    }
}

export default KosmoLitterMore;