import React from 'react';
import Logo from '../images/Contact/SHLogo.png';

import { column, row, halfImage, contactWord } from '../../app/styles';


class Contact extends React.Component {


    render() {
        return (
            <div style={column}>
                <div style={contactWord}>
                    <h1>Tina and Dan Sander</h1>
                    <h2>Mead, WA</h2>
                    <h2>509.795.4400</h2>
                    <h2>tina.d.sander@gmail.com</h2>
                </div>
                <div style={row}>
                    <img style={halfImage} src={Logo} />
                </div>
            </div>
        );
    }
}

export default Contact;