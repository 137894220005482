import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";
import Home from './pages/Home';
import Girls from './pages/Girls';
import Boys from './pages/Boys';
import Puppies from './pages/Puppies';
import RainbowBridge from "./pages/RainbowBridge";
import OurFamily from "./pages/OurFamily";
import Contact from "./pages/Contact";
import Farm from "./pages/FarmLife";



const useTabStyles = makeStyles({
    root: {
        justifyContent: "center",
        background: 'linear-gradient(45deg, grey, white, grey)',
        borderRadius: 3,
        borderTop: '5px solid black',
        borderBottom: '5px solid black',
        marginBottom: '5px',
        height: 48,
    },
    scroller: {
        flexGrow: "0"
    },
    wrapper: {
        fontWeight: "1000",
        color: "dark-grey"
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={value}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const TabsContainer = () => {
    const classes = useTabStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };

    const barStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderBottom: '2px solid white',
        borderTop: '2px solid white',
        height: 48,
    };

    const textStyle = {
        color: 'white',
        fontWeight: '500'
    }

    const pages = [
        "Home",
        "Girls",
        "Boys",
        "Rainbow Bridge",
        "Puppies",
        "Our Family",
        "Homestead Life",
        "Contact"
    ];


    return (
        <div>
            <Tabs
                classes={{ root: classes.root, scroller: classes.scroller }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant={"scrollable"}
                scrollButtons={"on"}
            >
                {pages.map((page, index) => (
                    <Tab {...a11yProps(index)} classes={{ wrapper: classes.wrapper }} key={index} label={page} value={index} />
                ))}
            </Tabs>
            <div style={barStyle} color="text.primary">
                <h1 style={textStyle}>{pages[value]}</h1>
            </div>
            <TabPanel value={value} index={0}>
                <Home />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Girls />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Boys />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <RainbowBridge />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Puppies />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <OurFamily />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <Farm />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <Contact />
            </TabPanel>
        </div>
    );
};

export default TabsContainer;