import React from 'react';
import Bear from '../images/Puppies/ChipLitter/Bear.jpg';
import Georgia from '../images/Puppies/ChipLitter/Georgia.jpg';
import Georgia2 from '../images/Puppies/ChipLitter/Georgia2.jpg';
import Henry1 from '../images/Puppies/ChipLitter/Henry1.jpg';
import Kegger from '../images/Puppies/ChipLitter/Kegger.jpg';
import pup1 from '../images/Puppies/ChipLitter/pup1.jpg';
import pup2 from '../images/Puppies/ChipLitter/pup2.jpg';
import pup3 from '../images/Puppies/ChipLitter/pup3.jpg';
import pup6 from '../images/Puppies/ChipLitter/pup6.jpeg';
import pup7 from '../images/Puppies/ChipLitter/pup7.jpg';

import { column, row, imageSeparator, halfImage } from '../../app/styles';

class ChipLitterMore extends React.Component {

    render() {
        return (
            <div style={column}>
                <div style={{ width: '100%', marginTop: '10px' }}></div>
                <div style={row}>
                    <img style={halfImage} src={Bear} alt="Puppy One" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Georgia} alt="Puppy Two" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={Georgia2} alt="Puppy Three" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Kegger} alt="Puppy Four" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={pup1} alt="Puppy Five" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={pup2} alt="Puppy Six" />
                </div><div style={row}>
                    <img style={halfImage} src={Henry1} alt="Puppy Seven" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={pup6} alt="Puppy Eight" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={pup7} alt="Puppy Seven" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={pup3} alt="Puppy Eight" />
                </div>
            </div>
        );
    }
}

export default ChipLitterMore;