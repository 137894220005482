import React from 'react';
import MiaOnMaxBack from '../images/RainbowBridge/MiaOnMaxBack.jpg';
import Maximus from '../images/RainbowBridge/Maximus.jpeg';
import SamMax from '../images/RainbowBridge/sam_&_max.jpeg';
import Samson from '../images/RainbowBridge/Samson.jpeg';
import Duke from '../images/RainbowBridge/DukeHeadShot.jpeg';
import DukeBaby from '../images/RainbowBridge/Duke&Baby.jpeg';

import { column, row, leftRow, twoImage, imageSeparator } from '../../app/styles';

class RainbowBridge extends React.Component {

    render() {
        return (
            <div style={column}>
                <div style={leftRow}>
                    <h2>Sanders’ Maximus Mischief</h2>
                </div>
                <div style={row}>
                    <img style={twoImage} src={Maximus} alt="Maximus" />
                    <div style={imageSeparator}></div>
                    <img style={twoImage} src={MiaOnMaxBack} alt="Mia on Max" />
                </div>
                <div style={column}>
                    <div style={{ width: '77%', color: 'white' }}>
                        <p>
                        Max (born 5/7/2004 in Snohomish WA) had to be one of the sweetest dogs ever! He was a great companion and hunting dog, too. He was also such lovely 
                        specimen of a Labrador that we thought he would be our first dog to start breeding. The timing still wasn’t right to have a breeding pair so we eventually 
                        neutered him. He loved and mourned his big brother Samson and patiently tolerated the new puppy, Mia. (Though he guarded her furiously from other dogs as if he loved her.) 
                        We lost Max to a splenic tumor on November 15, 2014. I still can’t speak of the times I’ve had to put a beloved dog down without the tears coming...
                        </p>
                        <hr></hr>
                    </div>
                </div>


                <div style={leftRow}>
                    <h2>Samson</h2>
                </div>
                <div style={row}>
                    <img style={twoImage} src={Samson} alt="Samson" />
                    <div style={imageSeparator}></div>
                    <img style={twoImage} src={SamMax} alt="Sam and Max" />
                </div>
                <div style={column}>
                    <div style={{ width: '77%', color: 'white' }}>
                        <p>
                        There is so much I could write about Samson. He was the best! (Don’t tell the other dogs I said that.) He was the brightest. He was the most loyal. He was 
                        the most protective. He was a good bird dog too. (Bird hunting was something that Dan did casually on and off for most of our marriage though football coaching for several 
                        years sidelined his hunting habits.) We got Sam from a breeder in Reardon WA in Nov 2001. His dad was a champion but his mom was young and not papered yet (accidental breeding) 
                        so we fixed Sam since we didn’t have any papers for him anyway. Breeding wasn’t quite on our horizon yet anyway. We lost Sam to a cancer of some sort on Feb 5, 2013. Labs just 
                        don’t live long enough. That is their only downfall.
                        </p>
                        <hr></hr>
                    </div>
                </div>


                <div style={leftRow}>
                    <h2>Duke</h2>
                </div>
                <div style={row}>
                    <img style={twoImage} src={Duke} alt="Duke" />
                    <div style={imageSeparator}></div>
                    <img style={twoImage} src={DukeBaby} alt="DukeBaby" />
                </div>
                <div style={column}>
                    <div style={{ width: '77%', color: 'white' }}>
                        <p>
                        We adopted Duke through the Seattle Purebred Dog Rescue in the winter of 1992 in Graham WA. He was just over a year old. I had never seen a chocolate lab 
                        before but it didn’t take long for me to fall in love with him. After all, chocolate is an addiction for me! It was love at first site for Dan. He wasn’t going to 
                        leave that place without Duke! Duke quickly adapted to our family (it was just Katie then as I was pregnant with Stephen) and caught on to pheasant hunting quickly also. 
                        He was such a smart dog and was so good with baby Stephen when he came crawling along. Duke was always game for anything…even floating on an inner tube on the lake with me. 
                        I think he would have water-skied also if we had taught him. Someone or something poisoned our Duke in Feb 2001. It was one of the hardest days of my life. I had never owned a 
                        dog for more than a few years prior to Duke. At first, I swore off of owning another dog ever again because the pain was too much but then Dan came home with Samson and my first 
                        Labrador baby was added to our family of now three kids. Sam became my 4th child!
                        </p>
                        <hr></hr>
                    </div>
                </div>
            </div>
        );
    }
}

export default RainbowBridge;