import React from 'react';
import One from '../images/Puppies/NobleLitter/one.jpeg';
import Two from '../images/Puppies/NobleLitter/two.jpeg';
import Three from '../images/Puppies/NobleLitter/three.jpeg';
import Four from '../images/Puppies/NobleLitter/four.jpeg';

import { column, row, imageSeparator, halfImage } from '../styles';

class NobleLitterMore extends React.Component {

    render() {
        return (
            <div style={column}>
                <div style={{ width: '100%', marginTop: '10px' }}></div>
                <div style={row}>
                    <img style={halfImage} src={One} alt="Puppy One" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Two} alt="Puppy Two" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={Three} alt="Puppy Three" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Four} alt="Puppy Four" />
                </div>
            </div>
        );
    }
}

export default NobleLitterMore;