import React from 'react';
import FullShasta from '../images/Girls/Shasta/FullPictureShasta.JPG';
import Shasta from '../images/Girls/Shasta/shasta.jpeg';
import ShastaStanding from '../images/Girls/Shasta/ShastaStanding.jpeg';
import ShastaTree from '../images/Girls/Shasta/ShastaTree.JPG';

import { column, row, imageSeparator, halfImage } from '../../app/styles';


class ShastaMore extends React.Component {

    render() {
        return (
            <div style={column}>
                <div style={{width:'100%', marginTop:'10px'}}></div>
                <div style={row}>
                    <img style={halfImage} src={FullShasta} alt="Shasta one" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={ShastaTree} alt="Shasta Tree" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Shasta posing for us out in the yard.</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Shasta waiting for apples to fall from the tree.</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={Shasta} alt="Shasta two" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={ShastaStanding} alt="Shasta Standing" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Shasta looking pretty and majestic.</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Baby Shasta showing off her free stack.</p>                    
                </div>
            </div>
        );
    }
}

export default ShastaMore;