import React from 'react';
import banner from './images/common/SHLBanner.jpg'
import '../App.css';


class Header extends React.Component {

    headerStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };

    imageStyle = {
        border: "5px solid black",
        width: "98%", 
        height: "20%",
        marginTop:"20px",
        marginBottom: "10px"
    }
    
    render() {
        return (
        <div style={this.headerStyle}>
            <img src={banner} alt={'banner'} style={ this.imageStyle }/>
        </div>
        );
    }
}

export default Header;