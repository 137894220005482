import React from 'react';
import Family from '../images/OurFamily/family.jpg';
import DanAndCar from '../images/OurFamily/dan&car.jpg';
import Flowers from '../images/OurFamily/flowers.jpeg';
import ShastaLeg from '../images/OurFamily/shasta_leg.jpeg';
import FlagPainting from '../images/OurFamily/FlagPainting.jpeg';
import TinaFish from '../images/OurFamily/tina_fish.jpeg';
import Willow from '../images/OurFamily/Willow.jpeg';
import ShastaPaint from '../images/OurFamily/ShastaPainting.jpeg';

import { column, row, leftRow, fullImage, halfImage, imageSeparator } from '../../app/styles';

class OurFamily extends React.Component {

    render() {
        return (
            <div style={column}>
                <div style={column}>
                    <div style={{ width: '77%', color: 'white' }}>
                        <p>
                        Dan and I met and married in 1985. I was in mortgage and he was in furniture sales. A few years later, he would go back to school and 
                        get his teaching certificate. Teaching was a calling and a ministry for Dan. It was not the love of his job that has kept him doing it 
                        since 1989, but the love of the kids. He taught vo-tech classes at the middle school level and coached girls basketball. 
                        His football coaching days ended when the district shut down the JH program a few years ago. I switched over from mortgage to real estate in 1988 
                        when my parents opened their own office. It has helped me to be a stay at home Mom, and now Grandma, for 30+ years now!
                        </p>
                        <p>
                        Dan took an early retirement in June 2021 and we moved to the Spokane area. I am starting my real estate business all over again 
                        here now. We are building our new home on a 10 acre wooded parcel we purchased in 2020. When we are not working on the house, we 
                        enjoy activities with our family and dogs such as camping, fishing and hunting. We also try to squeeze in some time for riding our motorcycles, 
                        woodworking projects (Dan) and watercolor painting (me). I have started squeezing in time to do commissioned pet portraits also. You can see more 
                        about that at <a style={{color:"grey"}} href="www.trinitywatercolors.com" target="_blank">www.trinitywatercolors.com</a> or at 
                        <a style={{color:"grey"}} href="https://www.facebook.com/Tina-Sander-Artisttrinitywatercolorscom-129384030564319" target="_blank"> Tina Sander Artist - trinitywatercolors.com on Facebook.</a>

                        </p>
                    </div>
                    <img style={halfImage} src={Family} alt="Family" />
                </div>
                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                    <p>
                    We have three grown children. Katie is married to Reed and they have 2 girls, Caroline and Georgia and they live in Bothell. 
                    Stephen is married to Emily and they live close by us here in Mead. Ben is married to Holly and they live in the Spokane Valley.
                    </p>
                    <hr></hr>
                </div>
                <div style={row}>
                    <img style={halfImage} src={TinaFish} alt="Tina Fishing" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={ShastaLeg} alt="Resting Shasta" />
                </div>
                <div style={column}>
                    <img style={halfImage} src={DanAndCar} alt="Dan and Caroline" />
                </div>
                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                </div>
                <div style={row}>
                    <img style={halfImage} src={FlagPainting} alt="Tina Flag" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Willow} alt="Tina Willow" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={Flowers} alt="Tina Flowers" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={ShastaPaint} alt="Tina Shasta" />
                </div>
                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                </div>
                <div style={leftRow}>
                    <h2 style={{margin:"0px", color:"white"}}>People and Places we Support/Follow</h2>
                </div>
                <div style={leftRow}>
                    <p style={{margin:"0px", color:"white"}}>
                        Dr. Karen Becker, holistic veterinarian: <a style={{color:"grey"}}href="https://www.facebook.com/doctor.karen.becker/" target="_blank">facebook.com/doctor.karen.becker</a>
                        <br />
                        Dr. W. Jean Dodds of Hemopet for her vaccination protocol: <a style={{color:"grey"}} href="https://www.facebook.com/DrJeanDoddsHemopetNutriScan/" target="_blank">facebook.com/DrJeanDoddsHemopetNutriScan</a>
                        <br />
                        Rodney Habib’s Ted Talk about dog life longevity: 
                        <a style={{color:"grey"}} href="https://www.youtube.com/watch?v=1sE96vd8W40" target="_blank">youtube.com/watch?v=1sE96vd8W40</a>
                    </p>
                </div>
                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                </div>
            </div>
        );
    }
}

export default OurFamily;