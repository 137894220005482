import React from 'react';
import Remi from '../images/Girls/Remi/Remi.jpg';
import RemiFive from '../images/Girls/Remi/RemiFive.jpeg';
import RemiFour from '../images/Girls/Remi/RemiFour.jpeg';
import RemiSitting from '../images/Girls/Remi/RemiSitting.jpeg';
import RemiThree from '../images/Girls/Remi/RemiThree.jpeg';
import RemiTwo from '../images/Girls/Remi/RemiTwo.jpeg';

import { column, row, imageSeparator, halfImage } from '../../app/styles';

class RemiMore extends React.Component {

    render() {
        return (
            <div style={column}>
                <div style={{width:'100%', marginTop:'10px'}}></div>
                <div style={row}>
                    <img style={halfImage} src={Remi} alt="Remi puppy" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={RemiFour} alt="Remi Beads" />
                </div>
                <div style={row}>
                    <img style={halfImage} src={RemiFive} alt="Remi pretty" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={RemiSitting} alt="Remi place" />
                </div>
            </div>
        );
    }
}

export default RemiMore;