import React from 'react';
import AllThePups from '../images/Farm/allthepups.jpg';
import Birdies from '../images/Farm/birdies.jpg';
import BruRemi from '../images/Farm/BruRemi.jpg';
import CarolineFood from '../images/Farm/caroline_food.jpg';
import CherryPicking from '../images/Farm/cherry_picking.jpg';
import Deck from '../images/Farm/deck.jpg';
import DogFood from '../images/Farm/dogfood.jpg';
import GandC from '../images/Farm/GandC.jpeg';
import Garden from '../images/Farm/garden.jpg';
import Gloves from '../images/Farm/gloves.jpg';
import Jars from '../images/Farm/jars.jpg';
import MiaBath from '../images/Farm/mia_bath.jpg';
import HotTub from '../images/Farm/hottub.jpeg';
import Projects from '../images/Farm/projects.jpg';
import PupFloor from '../images/Farm/pup8.jpeg';
import PuppiesGate from '../images/Farm/puppies_at_gate.jpg';
import PupRun from '../images/Farm/pups.jpg';
import PuppiesPen from '../images/Farm/pups5.jpg';
import Remodel from '../images/Farm/remodel.jpg';
import RemodelBefore from '../images/Farm/remodel-before.jpg';
import ShastaBath from '../images/Farm/shasta_bath.jpg';
import StephenFloor from '../images/Farm/stephen.jpg';
import MiaMax from '../images/Farm/three.jpg';
import Kisses from '../images/Farm/two.jpg';
import Window from '../images/Farm/window.jpg';
import MiaBox from '../images/Farm/MiaBox.jpeg';
import one from '../images/Farm/one.JPG';
import two from '../images/Farm/two.jpeg';
import three from '../images/Farm/three.jpeg';
import four from '../images/Farm/four.jpeg';
import five from '../images/Farm/five.JPG';
import oldandnew from '../images/Farm/newandold.jpg';
import newhouse1 from '../images/Farm/newhouse1.jpg';
import newhouse2 from '../images/Farm/newhouse2.jpg';
import HomeUpdate from '../images/Farm/HomeUpdate.jpeg';


import { column, row, imageSeparator, halfImage, mostImage } from '../../app/styles';


class Farm extends React.Component {

    render() {
        return (
            <div style={column}>
                <img style={mostImage} src={HomeUpdate} alt="Home Update" />
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"70%"}}>The dog run is almost done and the new fence is coming long. Dan is building it using trees from our property.</p>
                </div>
                 <div style={row}>
                    <img style={halfImage} src={newhouse1} alt="new house 1" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={newhouse2} alt="new house 2" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"70%"}}>We made a big move from Monroe WA to Mead WA in July 2021. We started construction of our new home
                    on ten acres on the east slope of Greenbluff in September of that year. We lived in our pole building and RV during construction and finally
                    took occupancy in December 2022. We ended up doing a lot of the work ourselves due to inflation costs and are still finishing a lot of stuff
                    as we unpack. But we are so glad to be in finally! Here's a few photos of the process and I'll post more as we finish up.</p>
                </div>
                <div style={row}>
                    <img style={halfImage} src={one} alt="georgia and septic system" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={oldandnew} alt="oldandnew" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Sweet Georgia is showing off our newly installed septic system. </p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>When the old meets the new - Mia Bella and Bonnie hanging out with Georgia.</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={two} alt="ocean shores photo" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={three} alt="dogs on porch" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Taking a break from our construction project to meet up with some family for some late season camping at Ocean shores. </p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Hanging out at Uncle Jerry and Aunt Cyndi’s home for some fun family time!</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={four} alt="new home 1" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={five} alt="Shasta Box" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>This is where we are living while we build our new home. Our RV/home is in the center bay and we have a studio apartment in the bay on the right. </p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Here you can see the foundation for our home. Framing should start by the end of February 2022.</p>                    
                </div>

                <div style={row}>
                    <img style={halfImage} src={GandC} alt="G and C" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={MiaBox} alt="Shasta Box" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Sweet baby Georgia and Caroline.</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Mia checking out Shasta's new whelping box.</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={BruRemi} alt="Bru and Remi" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={AllThePups} alt="All the pups" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Remi and Bru sharing a bed.</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Shasta's pick of the litter Remi with the pack.</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={PupFloor} alt="C on Floor" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={PuppiesPen} alt="Pups in Pen" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Caroline and Remi hanging out on the kitchen floor!</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Time for post-playtime nap!</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={RemodelBefore} alt="Remodel Before" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Remodel} alt="Remodel" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>We decided to replace the carpet throughout the main floor with new tile. So far so good!</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Final product of the tiling.</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={MiaBath} alt="Mia Bathtime" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={ShastaBath} alt="Shasta Bathtime" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Mia Bathtime</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Shasta Bathtime</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={CarolineFood} alt="Food Making" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={StephenFloor} alt="Stephen on Floor" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Caroline helping us prepare the dog food.</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Our first Christmas. Stephen and the dogs worked hard getting the tree up.</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={CherryPicking} alt="Dan Cherry Picking" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Gloves} alt="Gloves" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Yes, we have cherry trees in Western WA and they are yummy!</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>I was shoveling top soil into the garden. When I went to take a break, I tossed my gloves over the side of the truck. When I came back to get them, 
                    this is how they had landed. Not kidding!</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={Birdies} alt="Birdies" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={PupRun} alt="Dog Run" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Our resident swallows overlooking my dahlia garden.</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>New dog run is almost finished. Doggies have to test it out first.</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={Window} alt="Window" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={PuppiesGate} alt="Dogs at Gate" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Demolition Dan making room for my new garden window!</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>May we please come with you to get the mail?</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={Garden} alt="Garden" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Deck} alt="Deck" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Next year, I'm putting the dahlias in a different location and this will just be my rose garden. These dahlias got taller than me and kind of took over everything. 
                    Maybe I'll look for a shorter variety...</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Dan is rebuilding the front decks. I'll post the after picture some time next year!</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={Projects} alt="Projects" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Jars} alt="Dog Food Jars" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>Barn moving day! (Well, this really used to have a spa in it but we sold it. Making it a pool/spa supply room instead.)</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>We had a crash course in how to can as we have Italian plums, Honeycrisp Apples, Pippin Apples, and Bosc Pears. We also have blueberries, cherries, and four varieties of grapes. 
                    Next year we hope to add a small vegetable garden….if we have any energy left!</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={MiaMax} alt="Mia and Max" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Kisses} alt="Puppy Kisses" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>An old one of Mia hanging out with sweet Max.</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Caroline getting kisses from our 7/19 litter.</p>                    
                </div>
                <div style={row}>
                    <img style={halfImage} src={HotTub} alt="Family Hot Tub" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={DogFood} alt="Making Dog Food" />
                </div>
                <div style={{display:"flex", width:'77%', justifyContent:"center", textAlign:"center", marginBottom:"20px"}}>
                    <p style={{margin:"0px", width:"35%"}}>How many Sanders can fit in the Hot Tub at once?</p>
                    <div style={imageSeparator}></div>
                    <p style={{margin:"0px", width:"35%"}}>Our routine making of the dog food.</p>                    
                </div>
            </div>
        );
    }
}

export default Farm;