import React from 'react';
import './App.css';
import Header from './app/Header';
import TabsContainer from './app/TabsContainer';
import Footer from './app/Footer';






export default class App extends React.Component {
  state = {
    active: "home",
  }


  render() {
    return (
      <div>
        <Header />
        <TabsContainer active={this.state.active}>
        </TabsContainer>
        <Footer />
      </div>
    );
  }
}

