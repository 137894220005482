import React from 'react';
import JagerOne from '../images/Boys/JagerOne.jpeg';
import JagerTwo from '../images/Boys/JagerTwo.jpeg';
import JagerThree from '../images/Boys/JagerThree.jpeg';
import JagerFour from '../images/Boys/JagerFour.jpeg';
import { column, row, leftRow, twoImage, imageSeparator, halfImage } from '../../app/styles';

class Boys extends React.Component {

    render() {
        return (
            <div style={column}>
                <div style={leftRow}>
                    <h2 style={{marginBottom:"0"}}>Jäger</h2>
                </div>
                <div style={leftRow}>
                    <h3 style={{margin:"0px"}}>Sander Homestead's Mighty Hacker Hunter</h3>
                </div>

                <img style={twoImage} src={JagerOne} alt="Jager" />

                <div style={column}>
                    <div style={{ width: '77%', color: 'white' }}>
                        <hr></hr>
                        <p>
                        Jäger is from Shasta’s first litter (with Chip). He was returned to us at 6 months old as the 
                        family that had him had their work situation change. Luckily for Jäger, we decided to keep him in the family/pack and he became Stephen’s dog.
                        </p>
                        <hr></hr>
                    </div>
                </div>
                <div style={row}>
                    <img style={halfImage} src={JagerFour} alt="Jager Four" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={JagerThree} alt="Jager Three" />
                </div>
                <img style={halfImage} src={JagerTwo} alt="Jager Two" />
            </div>
        );
    }
}

export default Boys;