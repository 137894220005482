import React from 'react';
import PuppyWagon from '../images/Puppies/PuppyWagon.jpg';
import Wagging from '../images/Puppies/wagging.GIF';
import Kosmo from '../images/Puppies/Can.jpg';
import Pedigree from '../images/Puppies/kosmo-pedigree_orig.jpg';
import ShastaBear from '../images/Puppies/ShastaBear.JPG';
import Chip from '../images/Puppies/chip.jpg';
import Player from '../images/Puppies/winner.jpg';
import ChipPuppies from '../images/Puppies/ChipPuppies.jpg';
import ChipLitterMore from '../PhotoShow/ChipLitterMore';
import PlayerLitterMore from '../PhotoShow/PlayerLitterMore';
import KosmoLitterMore from '../PhotoShow/KosmoLitterMore';
import NobleLitterMore from '../PhotoShow/NobleLitterMore';
import puppies from '../images/Puppies/IMG_7890.JPG';
import Noble from '../images/Puppies/Noble.jpeg'
import Butler from '../images/Puppies/Butler.jpg'
import RemiPups from '../images/Puppies/RemiPups.jpeg'
import RemiPups1 from '../images/Puppies/RemiPups1.jpeg'
import { column, row, leftRow, halfImage, seeMoreButton, imageSeparator, mostImage } from '../../app/styles';

class Puppies extends React.Component {

    constructor() {
        super();
        this.state = {
            chipMoreShow: false,
            playerMoreShow: false,
            kosmoMoreShow: false,
            nobleMoreShow: false
        }
    }

    render() {
        return (
            <div style={column}>
                <div style={row}>
                    <img style={mostImage} src={puppies} alt="More Puppy Wagon" />
                </div>
                <div style={column}>
                    <div style={{ width: '77%', color: 'white' }}>
                        <hr></hr>
                        <p>
                            Our puppies are sold on a Limited Registration. This means that they cannot be used for breeding or showing in the AKC arena thus we require that they be spayed
                            or neutered. It is best to wait until the dog is close to 2 years of age when their growth plates have closed. Also, spaying a female too young has some risks involved
                            such as Spay Incontinence. If someone that owns one of our beautiful puppies later decides that they would like to show, we can discuss reversing the registration. Our
                            puppies are also sold on a Lifetime First Right of Refusal. This means that if the owner cannot keep the dog in the future, it will be returned to our home - after all,
                            it is our offspring!
                        </p>
                        <p>
                            We screen each and every potential owner carefully as we look forward to having long and happy relationships with our puppies' new owners. If you are interested
                            in adopting one of our puppies, please email me for more information and an application. We do not accept deposits until the puppies are whelped. At that point,
                            deposits will be taken on a first come, first served basis. Full payment will be due upon pick up of the pup. Deposits are non-refundable.
                        </p>
                        <hr></hr>
                    </div>
                </div>
                <div style={row}>
                    <img style={halfImage} src={PuppyWagon} alt="Puppy Wagon" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Wagging} alt="Wagging" />
                </div>
                
                <div style={{width:'77%'}}>
                    <hr></hr>
                </div>

                <div style={leftRow}>
                    <h2>Available Puppies</h2>
                </div>
                <div style={{width:'77%'}}>
                    <hr></hr>
                </div>
                <div style={row}>
                    <img style={halfImage} src={RemiPups} alt="Pups" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={RemiPups1} alt="Puppies" />
                </div>
                <div style={leftRow}>
                    <h5 style={{ margin: "0px", color: "white" }}>Remi was bred to...</h5>
                </div>
                <div style={leftRow}>
                    <h3 style={{ margin: "0px", color: "white" }}>CH Soundviews Epoch Love Story (Butler)</h3>
                </div>
                <div style={leftRow}>
                    <p style={{ marginTop: "0px", color: "white" }}>
                        Announcing Remi and Butler's box of chocolates! 11 puppies arrived on 3/22/23. 6 boys, 5 girls. <br></br>
                    </p>
                </div>
                <div style={row}>
                    <img style={halfImage} src={Butler} alt="Butler" />
                    {/* <div style={imageSeparator}></div>
                    <img style={halfImage} src={Pedigree} alt="Noble's Pups" /> */}
                </div>
                <div style={leftRow}>
                    <h3 style={{ margin: "0px", color: "white" }}>
                        This litter will be ready to go to their forever homes on 5/17/2023. Please reach out if you are interested in one of our amazing puppies. We only have a few left.
                    </h3>
                </div>
                <div style={leftRow}>
                    <p style={{ marginTop: "0px", color: "white" }}>Butler can be seen here: <a style={{ color: "white" }} href="https://www.soundviewlabradors.com/butler" target="_blank">https://www.soundviewlabradors.com/butler</a></p>
                </div>
                {/* <div style={row}>
                    <h2 style={{ color: "white" }}>There are no puppies available right now. Please check back for updates on planned future litters!</h2>
                </div> */}
                <div style={{width:'77%'}}>
                    <hr></hr>
                </div>

                <div style={leftRow}>
                    <h2>Past Litters</h2>
                </div>
                <div style={{width:'77%'}}>
                    <hr></hr>
                </div>

                {/* <div style={leftRow}>
                    <h5 style={{ margin: "0px", color: "white" }}>Remi was bred to...</h5>
                </div> */}
                <div style={leftRow}>
                    <h3 style={{ margin: "0px", color: "white" }}>Am Ch/Can GCH Soundview’s Noble Heir to Mykiss</h3>
                </div>
                <div style={row}>
                    <img style={halfImage} src={Noble} alt="Noble" />
                    {/* <div style={imageSeparator}></div>
                    <img style={halfImage} src={Pedigree} alt="Noble's Pups" /> */}
                </div>
                <div style={leftRow}>
                    <p style={{ marginTop: "0px", color: "white" }}>
                        Shasta’s 4th and final litter arrived on April 6, 2021, the day before my birthday! Eleven adorable black and chocolate 
                        puppies delivered by C-section. Sired by Am Ch/Can GCH Soundview’s Noble Heir to Mykiss.
                    </p>
                </div>
                <div style={leftRow}>
                    <p style={{ marginTop: "0px", color: "white" }}>Noble can be seen here: <a style={{ color: "white" }} href="https://www.mykisslabradors.com/noble.html" target="_blank">https://www.mykisslabradors.com/noble.html</a></p>
                </div>
                <div style={row}>
                    <button style={seeMoreButton} onClick={() => {
                        this.setState({ nobleMoreShow: !this.state.nobleMoreShow });
                    }}>
                        Click to see more pictures of the 4/6/2021 litter!
                </button>
                </div>
                {this.state.nobleMoreShow ? (
                    <>
                        <NobleLitterMore />
                        <button style={seeMoreButton} onClick={() => {
                            this.setState({ nobleMoreShow: !this.state.nobleMoreShow });
                        }}>
                            Close
                        </button>
                    </>)
                    : <></>}
                <div style={{width:'77%'}}>
                    <hr></hr>
                </div>



                <div style={leftRow}>
                    <h3 style={{ margin: "0px", color: "white" }}>Can CH Hopeville Kosmic Destiny To Clearwater WC TKN</h3>
                </div>
                <div style={row}>
                    <img style={halfImage} src={Kosmo} alt="Kosmo" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={Pedigree} alt="Kosmo's Pedigree" />
                </div>
                <div style={leftRow}>
                    <p style={{ marginTop: "0px", color: "white" }}>
                        A beautiful litter of blacks and chocolates arrived on 2/17/2020.
                    </p>
                </div>
                <div style={leftRow}>
                    <p style={{ marginTop: "0px", color: "white" }}>Kosmo can be seen here: <a style={{ color: "white" }} href="https://cwlabradors.com/boys/kosmo/" target="_blank">https://cwlabradors.com/boys/kosmo/</a></p>
                </div>
                <div style={row}>
                    <button style={seeMoreButton} onClick={() => {
                        this.setState({ kosmoMoreShow: !this.state.kosmoMoreShow });
                    }}>
                        Click to see more pictures of the 2/17/2020 litter!
                </button>
                </div>
                {this.state.kosmoMoreShow ? (
                    <>
                        <KosmoLitterMore />
                        <button style={seeMoreButton} onClick={() => {
                            this.setState({ kosmoMoreShow: !this.state.kosmoMoreShow });
                        }}>
                            Close
                        </button>
                    </>)
                    : <></>}



                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                </div>
                <div style={leftRow}>
                    <h3 style={{ marginTop: "0px", color: "white" }}>CH Nipntuck Well Played - "Player"</h3>
                </div>
                <div style={row}>
                    <img style={halfImage} src={Player} alt="Player" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={ShastaBear} alt="Shasta Puppies" />
                </div>
                <div style={leftRow}>
                    <p style={{ marginTop: "0px", color: "white" }}>
                        Shasta had five adorable black bundles of joy on 7/13/2019.<br></br>
                        Two males and three females. Our Sander Homestead Black Eagle Benelli is from this litter.
                    </p>
                </div>
                <div style={leftRow}>
                    <p style={{ marginTop: "0px", color: "white" }}>Player can be seen here: <a style={{ color: "white" }} href="http://nipntucklabs.com/Player.html" target="_blank">http://nipntucklabs.com/Player.html</a></p>
                </div>
                <div style={row}>
                    <button style={seeMoreButton} onClick={() => {
                        this.setState({ playerMoreShow: !this.state.playerMoreShow });
                    }}>
                        Click to see more pictures of the 7/13/2019 Litter!
                </button>
                </div>
                {this.state.playerMoreShow ? (
                    <>
                        <PlayerLitterMore />
                        <button style={seeMoreButton} onClick={() => {
                            this.setState({ playerMoreShow: !this.state.playerMoreShow });
                        }}>
                            Close
                        </button>
                    </>)
                    : <></>}


                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                </div>
                <div style={leftRow}>
                    <h3 style={{ marginTop: "0px", color: "white" }}>Chip (MBISS MBOSS AM/CAN GCH MYKISS CHIPS' AHOY WC RN CGC)</h3>
                </div>
                <div style={row}>
                    <img style={halfImage} src={Chip} alt="Chip" />
                    <div style={imageSeparator}></div>
                    <img style={halfImage} src={ChipPuppies} alt="Chip Puppies" />
                </div>
                <div style={leftRow}>
                    <p style={{ marginTop: "0px", color: "white" }}>
                        This was our first litter ever. We raised the puppies in our home, “under foot” so they are part of our family from the start.
                        (Our dogs are all inside most of the time anyway.) We started the puppies on the Puppy Culture program (see www.puppyculture.com) as
                        my mentors have highly suggested it as a great way to start training them.
                    </p>
                </div>
                <div style={leftRow}>
                    <p style={{ marginTop: "0px", color: "white" }}>Chip can be seen here: <a style={{ color: "white" }} href="https://www.mykisslabradors.com/" target="_blank">https://www.mykisslabradors.com/</a></p>
                </div>
                <div style={row}>
                    <button style={seeMoreButton} onClick={() => {
                        this.setState({ chipMoreShow: !this.state.chipMoreShow });
                    }}>
                        Click to see more pictures of the 11/18/2018 Litter!
                </button>
                </div>
                {this.state.chipMoreShow ? (
                    <>
                        <ChipLitterMore />
                        <button style={seeMoreButton} onClick={() => {
                            this.setState({ chipMoreShow: !this.state.chipMoreShow });
                        }}>
                            Close
                        </button>
                    </>)
                    : <></>}
            </div>
        );
    }
}

export default Puppies;