import React from 'react';
import facebook from '../../src/app/images/common/fb_icon.png';


class Footer extends React.Component {

    footerStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        backgroundColor: "rgba(0,0,0,0.5)",
        border: "5px solid black",
        fontSize: "16px"
    };

    left = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "47.5%",
    }

    middle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "5%"
    }

    right = {
        display: "flex",
        alignItems: "left",
        textAlign: "center",
        justifyContent: "center",
        width: "47.5%",
    }

    image = {
        width: "40px",
        height: "40px"
    }
    
    render() {
        return (
        <div style={this.footerStyle}>
            <div style={this.left}>
                Sander Homestead & Labrador Retrievers
            </div>
            <div style={this.middle}>
                <a href="https://www.facebook.com/sanderhomesteadlabs/" target="_blank" rel="noopener noreferrer"><img src={facebook} style={this.image} alt="Facebook" /></a>
            </div>
            <div style={this.right}>
                Dan & Tina Sander<br />Mead, WA<br />509.795.4400<br />tina.d.sander@gmail.com
            </div>
        </div>
        );
    }
}

export default Footer;
