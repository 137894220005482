import React from 'react';
import MiaBella from '../images/Girls/MiaBella.JPG';
import RemiShow from '../images/Home/RemiShow.jpg';
import NelliSix from '../images/Girls/NelliSix.jpeg';
import ShastasPedigree from '../images/Girls/ShastasPedigree.png';
import RemisPedigree from '../images/Girls/RemisPedigree.png';
import ShastaMore from '../PhotoShow/ShastaMore';
import RemiMore from '../PhotoShow/RemiMore';
import NelliMore from '../PhotoShow/NelliMore';
import ShastaNew from '../images/Girls/ShastaNew.jpg';
import Bonnie from '../images/Girls/bonnie1.jpg';

import { column, row, leftRow, fullImage, halfImage, seeMoreButton } from '../../app/styles';


class Girls extends React.Component {

    constructor() {
        super();
        this.state = {
            shastaMoreShow: false,
            remiMoreShow: false,
            nelliMoreShow: false
        }
    }

    render() {
        return (
            <div style={column}>

                {/* <div style={column}>
                    <h3>Remi's Pedigree</h3>
                </div>
                <img style={fullImage} src={RemisPedigree} alt="Remi's Pedigree" />
                <div style={leftRow}>
                    <h2>Clearances</h2>
                </div>
                <div style={leftRow}>
                    <p>
                        OFA Heart Echo: Normal<br></br>
                        OFA Hips: Excellent<br></br>
                        OFA Elbows: Normal<br></br>
                        Eyes: Cleared annually<br></br>
                        EIC: Clear<br></br>
                        PRA: Clear<br></br>
                        HNPK: Clear<br></br>
                        CYS: Clear<br></br>
                        DM: Clear<br></br>
                        D Locus: Dilute Clear
                    </p>
                </div>
                <div style={{width:'77%'}}>
                <hr></hr>
                </div> */}
            
                <div style={leftRow}>
                    <h2>Sander Homestead's Lady Remington Carruthers</h2>
                </div>

                <img style={halfImage} src={RemiShow} alt="Remi" />

                <div style={row}>
                    <button style={seeMoreButton} onClick={() => {
                            this.setState({remiMoreShow: !this.state.remiMoreShow});
                        }}>
                            Click to see more pictures of Remi!
                    </button>
                </div>
                <div style={row}>
                    {this.state.remiMoreShow? 
                            <RemiMore />
                    : <></>}
                </div>
                <div style={row}>
                    {this.state.remiMoreShow? 
                        <button style={seeMoreButton} onClick={() => {
                            this.setState({remiMoreShow: !this.state.remiMoreShow});
                            }}>
                                Close
                        </button>
                    : <></>}
                </div>


                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                    <p>
                    Remi is co-owned and lives with our daughter and son-in-law. Reed has jumped on board with me in learning how to show dogs. 
                    His enthusiasm and love for his dog makes it all the more fun to do this hobby with him. Remi has earned her International Championship title 
                    and she has collected several other ribbons including "Best of Breed Bred by Exhibitor".
                    </p>
                    <hr></hr>
                </div>
                <div style={column}>
                    <h3>Remi's Pedigree</h3>
                </div>
                <img style={fullImage} src={RemisPedigree} alt="Remi's Pedigree" />
                <div style={leftRow}>
                    <h2>Clearances</h2>
                </div>
                <div style={leftRow}>
                    <p>
                        OFA Heart Echo: Normal<br></br>
                        OFA Hips: Excellent<br></br>
                        OFA Elbows: Normal<br></br>
                        Eyes: Cleared annually<br></br>
                        EIC: Clear<br></br>
                        PRA: Clear<br></br>
                        HNPK: Clear<br></br>
                        CYS: Clear<br></br>
                        DM: Clear<br></br>
                        D Locus: Dilute Clear
                    </p>
                </div>
                <div style={{width:'77%'}}>
                <hr></hr>
                </div>

                <div style={leftRow}>
                    <h2>Sander Homestead's Black Eagle Benelli</h2>
                </div>

                <img style={halfImage} src={NelliSix} alt="Nelli" />
                <div style={row}>
                    <button style={seeMoreButton} onClick={() => {
                            this.setState({nelliMoreShow: !this.state.nelliMoreShow});
                        }}>
                            Click to see more pictures of Nelli!
                    </button>
                </div>
                <div style={row}>
                    {this.state.nelliMoreShow? 
                            <NelliMore />
                    : <></>}
                </div>
                <div style={row}>
                    {this.state.nelliMoreShow? 
                        <button style={seeMoreButton} onClick={() => {
                            this.setState({nelliMoreShow: !this.state.nelliMoreShow});
                            }}>
                                Close
                        </button>
                    : <></>}
                </div>

                <div style={{ width: '77%', color: 'white' }}>
                    <hr></hr>
                    <p>
                    Benelli is our pick of the litter (keeper) puppy from Shasta’s litter with Player. She has her momma’s sweet, sweet temperament 
                    and is smart as a whip! Nelli started her show career in the Beginner Puppy classes and collected a few ribbons for Best of Breed Puppy 
                    in both AKC and International competitions. She also earned her International Championship title.
                    </p>
                    <hr></hr>
                </div>

                <div style={leftRow}>
                    <h2>Sanders' Shameless Shasta - Retired</h2>
                </div>

                <img style={halfImage} src={ShastaNew} alt="Shasta" />
                <div style={row}>
                    <button style={seeMoreButton} onClick={() => {
                            this.setState({shastaMoreShow: !this.state.shastaMoreShow});
                        }}>
                            Click to see more pictures of Shasta!
                    </button>
                </div>
                <div style={row}>
                    {this.state.shastaMoreShow? 
                            <ShastaMore />
                    : <></>}
                </div>
                <div style={row}>
                    {this.state.shastaMoreShow? 
                        <button style={seeMoreButton} onClick={() => {
                            this.setState({shastaMoreShow: !this.state.shastaMoreShow});
                            }}>
                                Close
                        </button>
                    : <></>}
                </div>

                <div style={column}>
                    <div style={{ width: '77%', color: 'white' }}>
                        <hr></hr>
                        <p>
                        Shasta was our first breeding girl and is now retired. She comes from excellent show lines and produced amazing puppies that have made us so proud! 
                        She now enjoys just being one of our pack, running our property or hogging our couch!
                        </p>
                        <hr></hr>
                    </div>
                </div>
                <div style={column}>
                    <h3>Shasta's Pedigree</h3>
                </div>
                <img style={fullImage} src={ShastasPedigree} alt="Shasta's Pedigree" />
                <div style={leftRow}>
                    <p>
                        OFA Heart Echo: Normal<br></br>
                        OFA Hips: Good<br></br>
                        OFA Elbows: Normal<br></br>
                        EIC: Clear<br></br>
                        Eyes: Cleared Annually<br></br>
                        PRA: clear on her dam, CBP on her sire<br></br>
                        HNPK: her dam is a carrier, sire is clear
                    </p>
                </div>
                <div style={{width:'77%'}}>
                <hr></hr>
                </div>


                <div style={leftRow}>
                    <h2>Mia Bella</h2>
                </div>
                <img style={halfImage} src={MiaBella} alt="Mia" />
                <div style={column}>
                    <div style={{ width: '77%', color: 'white' }}>
                        <hr></hr>
                        <p>
                        Mia Bella is the last of our “non-show” dogs. We got her in 2013 after losing Samson in 2012. She was the runt of her litter, but she quickly 
                        became the princess of our home. She broke all of the rules and got away with it. She still does, and she still reigns...
                        </p>
                        <hr></hr>
                    </div>
                </div>
            </div>
        );
    }
}

export default Girls;